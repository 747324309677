import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Blog from '../components/Blog/Blog';
import Button from '../components/Button/Button';
import Image from '../components/Image/Image';

import articlePdf from '../assets/sklepy.svg';

const Post1 = () => {
  const content = {
    img: 'post-title-monnari.png',
    desc: 'Wywiad z Piotrem Olczykiem - Dyrektorem Operacyjnym Monnari Trade',
    title: '„Zarządzanie rozproszoną strukturą nie należy do łatwych zadań”',
    excerpt:
      'O tym, jak realizację tego zadania uprościć oraz na czym dokładnie polega wyzwanie, rozmawialiśmy z Dyrektorem Operacyjnym Monnari Trade, panem Piotrem Olczykiem.',
  };

  const { img, desc, title, excerpt } = content;
  return (
    <Blog title={title} img={img} desc={desc} excerpt={excerpt}>
      <>
        <div className='block'>
          <div className='col-2-1'>
            <div>
              <p>
                <strong>
                  Monnari to ponad 170 sklepów na terenie całego kraju, a w nich około 1000 osób personelu
                  sprzedażowego. Ponad 30 pracowników funkcjonuje na co dzień w terenie, należą do nich kierownicy
                  regionów, mikroregionów itp. Jakie wyzwania wiążą się z codziennym zarządzaniem tak licznym
                  rozproszonym zespołem?{' '}
                </strong>
              </p>
              <p>
                Zarządzanie rozproszoną strukturą nie należy do łatwych zadań. W codziennych działaniach skupiamy się
                głównie na tym, aby zapewnić naszym klientkom jak najwyższy poziom obsługi, a ten rozpatrujemy bardzo
                szeroko - począwszy od ogólnie pojętego VMu, a skończywszy na wszystkich operacjach sprzedażowych i
                około sprzedażowych. Jakość wykonania każdego z drobnych elementów składa się na całość, czyli sprawne
                działanie sklepu każdego dnia. Skoordynowanie i wyegzekwowanie tej pozornie oczywistej pracy to
                prawdziwe wyzwanie.
              </p>
            </div>
            <Image filename='monnari-post.jpg' />
          </div>
        </div>
        <div className='block'>
          <p>
            <strong>
              Czy po wdrożeniu cHow codzienna praca stała się łatwiejsza? Jakie główne korzyści wynikające z korzystania
              z systemu Pan dostrzega?
            </strong>
          </p>
          <p>
            Wyzwania nie zniknęły - można nawet powiedzieć, że jest ich więcej, bo mamy większą świadomość bieżącej
            sytuacji. Dzięki wdrożeniu systemu cHow zyskaliśmy przede wszystkim możliwość płynnego monitorowania
            postępów w realizacji zadań w strukturze sklepu jak i wśród managerów średniego szczebla. Kierownicy
            regionalni oraz Zarząd zyskali narzędzie do oceny jakości pracy i wykorzystania czasu. Znacząco poprawiliśmy
            efektywność i jakość pracy. Dokładne sparametryzowanie poszczególnych procesów zasadniczo skróciło czas ich
            realizacji, a w przypadku niektórych zastąpiliśmy je nowymi, bardziej wydajnymi.
          </p>
        </div>
        <div className='block'>
          <p>
            <strong>
              Monnari pracuje z cHow od ponad pół roku. Zlecono przez system niemal 166 tysięcy zadań, a wykonano ponad
              90% z nich! Jest to dowód na dużą dyscyplinę w organizacji. W jaki sposób udało się Państwu osiągnąć tak
              wysoki wskaźnik już w pierwszych miesiącach pracy z nowym dla firmy systemem?
            </strong>
          </p>
          <p>
            Raporty w cHow stanowią odzwierciedlenie naszego dnia pracy. Firma Zalass Consulting adaptując dla nas
            system była w 100% otwarta na sugestie i potrzeby. Wspólnie zbudowaliśmy zespół, który opracował model
            działania pozwalający na osiągnięcie celu. Efektem tej pracy jest obecny kształt cHow.
          </p>
          <p>
            Ponad 90% efektywność w realizacji zadań osiągamy dzięki dopasowaniu systemu do specyfiki naszej firmy,
            wysokiej sprawność narzędzia, ale przede wszystkim obowiązkowości pracowników. Brak kłopotów technicznych,
            intuicyjne działanie, sprawny Help Desk, częste aktualizacje, nowe funkcjonalności sprawiają, że pracujemy z
            cHow bez obaw i problemów, a więc wśród pracowników nie ma oporu przez używaniem aplikacji.
          </p>
        </div>
        <div className='block'>
          <p>
            <strong>
              Tematy zadań zlecanych przez cHow są rozmaite – pytacie Państwo pracowników punktów sprzedaży o rzeczy
              związane z oznaczeniami promocji, wysyłane są instrukcje i raporty VM, zbieracie zamówienia odzieży
              pracowniczej, informacje o awariach terminali płatniczych, prowadzicie karty ocen pracowniczych itd. Kto
              jest głównym odbiorcą zbieranych danych? Jakie działy?
            </strong>
          </p>
          <p>
            cHow dostarcza informacje, z których - mogę to dziś z całą odpowiedzialnością powiedzieć – w formie raportów
            korzysta cała firma. Katalog raportów został tak zbudowany, że w zasadzie cHow stał się głównym kanałem
            wymiany informacji między sklepem, a strukturami zarządczymi i logistyką.
          </p>
        </div>
        <div className='block'>
          <p>
            <strong>Czy dane, które pozyskujecie Państwo przez cHow były wcześniej dostępne w organizacji?</strong>
          </p>
          <p>
            Dane oczywiście były dostępne wcześniej. Pozyskanie ich było jednak nie tak szybkie i nie tak systematyczne,
            jak ma to miejsce teraz. Po 2-3 miesiącach funkcjonowania systemu rozpoczęliśmy rozbudowę struktury raportów
            na nowe obszary. Efekt - poprawa efektywności i redukcja czasu wdrożenia zadań oraz rozwiązań.
          </p>
        </div>
        <div className='block'>
          <p>
            <strong>
              Mówi się, że zebranie danych to dopiero część sukcesu. Druga część to umiejętność ich wykorzystania. Jak
              to wygląda u Państwa? Czy pojawienie się dużych puli danych stanowiło dla Państwa na początku wyzwanie
              organizacyjne związane z tym, jak zorganizować proces „konsumowania” tej wiedzy? Jeśli tak, jak sobie z
              nim poradziliście?
            </strong>
          </p>
          <p>
            Ilość danych, jaką otrzymujemy każdego dnia jest ogromna. Wspomniałem, że z cHow korzysta bardzo dużo osób
            odpowiedzialnych za szereg procesów całej firmy. Informacja trafia bezpośrednio do komórki odpowiedzialnej
            za określony obszar. Wcześniej bywało z tym różnie, dzisiaj „bank danych” stanowi fundament do działań
            wszystkich managerów odpowiedzialnych za wsparcie sprzedaży.
          </p>
        </div>
        <div className='block'>
          <p>
            <strong>
              Bazując na Państwa doświadczeniu proszę powiedzieć, jakie czynniki stanowią przesłankę do tego, że
              wdrożenie w firmie systemu z kategorii Field Service Management jest zasadne? W jakich sytuacjach warto?
            </strong>
          </p>
          <p>
            Zasadność takiego wdrożenia jest w mojej ocenie niepodważalna. Stosunek zysków do nakładów jest bardzo
            korzystny. Zarządzanie rozproszoną strukturą wymaga zastosowania narzędzi do monitorowania pracy. Służy to
            przede wszystkim podniesieniu efektywności. Nie bez znaczenia jest także i to, że wdrożenie systemu
            znakomicie poprawia ocenę jakości procesów, jakie prowadzimy.
          </p>
        </div>
        <div className='block'>
          <p>
            <strong>
              A czy samo wdrożenie było dla Państwa trudne? Czasami słyszymy od klientów, że widzą potrzebę realizacji
              wdrożenia, ale zwlekają z nim, bo nie mogą znaleźć dobrego momentu na podjęcie tak dużego wysiłku
              organizacyjnego. Jakby się Pan odniósł do tego typu obaw?
            </strong>
          </p>
          <p>
            Z całą odpowiedzialnością mogę potwierdzić, że proces wdrożenia nie należy do trudnych. Filozofia działania
            narzędzia nie jest bardzo skomplikowana, Ważne jednak, aby projekt, który jest przygotowywany do wdrożenia
            był przemyślany - wtedy procedura wdrożenia powinna przebiec bez zakłóceń.
          </p>
        </div>
        <a href='Raport-ZachowaniaKonsumenckie.pdf' download>
          <Button text='Pobierz wywiad w PDF' type={1} icon={faCaretRight} />
        </a>
      </>
    </Blog>
  );
};

export default Post1;
